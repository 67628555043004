
import Modal from '@/modals/helpers/Modal.vue'
import Bus from '@/helpers/EventBus'
import { getNormalizedModalOptions } from '@/modals/helpers/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from '@/helpers/MiscellaneousHelpers'
import { ModalOptions } from '@/modals/helpers/types'

type ModalIndex = null | number

const bodyClasses = document.querySelector('body')!.classList

@Component({
  name: 'ModalHandler',
  components: { Modal },
})
export default class extends Vue {
  modals: ModalOptions[] = []

  mounted() {
    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Escape') {
        this.handleClose()
      }
    })

    window.addEventListener('resize', debounce(this.onResize))
  }

  created() {
    // Open a new modal
    Bus.$on('new:modal', (inputs) => {
      const options = getNormalizedModalOptions(inputs)

      if (this.modals.find((modal) => modal.name === options.name)) {
        /* eslint-disable-next-line no-console */
        console.warn(`Duplicate modals detected (name: '${options.name}')`)
        return
      }

      this.modals.push(options)

      // Let everyone else know that a new Modal is open
      Bus.$emit('opened:modal', {
        index: this.last,
        options,
      })

      bodyClasses.add('modal-open')

      // Enhance dialog usability by focusing the first interactive element.
      // Instead of the default close button focus, we prioritize the first input or button,
      // improving the user's workflow when interacting with the dialog.
      this.$nextTick(() => {
        const getElInModal = (selector): HTMLElement | null => {
          return document.querySelector(`dialog[data-name="${options.name}"] ${selector}`)
        }

        const el = getElInModal('input') ?? getElInModal(' button')

        if (el) {
          el.focus()
        }
      })
    })

    Bus.$on('update:modal', (data) => {
      const { name, props } = data
      const modal = this.modals.find((modal) => modal.name === name)
      if (modal) {
        modal.props = props
      }
    })

    // When a close event is received, close the modal instance
    Bus.$on('close:modal', (data) => {
      // If an $index was given on the data
      const index = data && data.$index ? data.$index : null
      const name = data?.name
      this.close(data, index, name)
    })

    Bus.$on('close:modal:all', () => {
      for (let i = this.modals.length - 1; i >= 0; i--) {
        this.close(null, i)
      }
    })
  }

  get last(): number {
    return this.modals.length - 1
  }

  // Close the modal and pass any given data
  close(data = null, index?: ModalIndex, name?: string | null) {
    // Can't close if there's no modal open
    if (this.modals.length === 0) return

    // If the index is either null or undefined
    if (typeof index !== 'number') index = this.last

    if (name) {
      index = this.modals.findIndex((modal) => modal.name === name)
      if (index === -1) return
    }

    const instance = this.modals[index]

    // Notify the app about this window being closed
    Bus.$emit('closed:modal', { index, instance, data })

    // Close callback
    if (instance?.onClose) instance.onClose(data)

    this.removeModal(data, index)

    this.$nextTick(() => {
      this.$root.refocusLastActiveElement()
    })
  }

  handleClose(data?, index?: ModalIndex, name?: string) {
    if (this.currentModal?.closeable) {
      this.close(data, index, name)
    }
  }

  // Remove the given index from the modals array
  removeModal(data, index: ModalIndex) {
    // If there's nothing to close, ignore it
    if (!this.modals.length) return

    if (index && !this.modals[index]) return

    // If there's no index, pop() it
    if (index === null) this.modals.pop()
    else this.modals.splice(index, 1)

    // And if it was the last window, notify that all instances are destroyed
    if (!this.modals.length) {
      bodyClasses.remove('modal-open')
      Bus.$emit('destroyed')
    }
  }
  get currentModal() {
    return this.modals[this.last]
  }

  onResize() {
    // Close any modals that are hidden after resize events. An open mobile-only modal blocks interaction with the
    // rest of the UI on desktop views.
    const components = this.$refs.modals as undefined | Modal[]
    if (components) {
      components.forEach((modal, index) => {
        const style = getComputedStyle(modal.$el as HTMLDialogElement)
        if (style.display === 'none') {
          this.close(null, index, null)
        }
      })
    }
  }

  @Watch('modals', { deep: true })
  classes() {
    bodyClasses.remove('desktop-modals-hidden', 'mobile-modals-hidden')

    if (this.modals.length && this.modals.every((modal) => modal.desktop === 'hidden')) {
      bodyClasses.add('desktop-modals-hidden')
    }

    if (this.modals.length && this.modals.every((modal) => modal.mobile === 'hidden')) {
      bodyClasses.add('mobile-modals-hidden')
    }
  }
}
