import LoginForm from '@/components/forms/LoginForm.vue'
import EventBus from '@/helpers/EventBus'
import { languageItem } from '@/language/helpers'
import { openModal } from '@/modals/helpers/api'
import { Vue } from 'vue-property-decorator'

export function openLoginModal(): Promise<void> {
  return new Promise((resolve, reject) => {
    const t = languageItem('loginForm')
    const modals = Vue.prototype.$modals
    openModal({
      component: LoginForm,
      name: 'login',
      title: t.title,
      size: 'sm',
      props: {
        showJoinLink: true,
      },
    })

    // TODO Handle all dismiss/close events on the modal like this?
    // TODO
    EventBus.$on('tix:login:dismiss', () => {
      modals.close()
      reject()
    })

    EventBus.$on('tix:login:success', () => {
      modals.close()
      resolve()
    })
  })
}
