import { CartApiResponse } from '@/api/Cart'
import { apiEntities } from '@/api/Helpers'
import { environment } from '@/helpers/Environment'
import { indexItemsById } from '@/helpers/IndexHelpers'

export function getTicketsFromCartForOrder(apiResponse: CartApiResponse, orderId: string): Ticket[] {
  const allowedCategories = new Set(environment.config.self_edit_rules!.categories!)

  const { event_template: allTemplates, ticket: tickets, ticket_group: allGroups } = apiEntities(apiResponse)

  const templates = indexItemsById(allTemplates)
  const groups = indexItemsById(allGroups)

  return tickets
    .filter((ticket) => {
      const template = templates[ticket.event_template_id]
      const group = groups[ticket.ticket_group_id]
      return group.handler === 'tickets' && allowedCategories.has(template.category)
    })
    .filter((ticket) => {
      // ticket_order_id is null for pending rescheduled tickets, they are assigned an order ID on checkout
      return !ticket.ticket_order_id || ticket.ticket_order_id === orderId
    })
}
