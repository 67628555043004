import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import type { FormInputField } from '@/components/forms/types'
import { appendUniqueItems, prependUniqueItems } from '@/helpers/ArrayHelpers'
import { environment } from '@/helpers/Environment'
import { builtInFields, customFields, IdentityFormData } from '@/helpers/IdentityHelpers'
import { csvToArray, toKebabCase } from '@/helpers/StringHelpers'
import { getRequiredFieldsByPaymentMethod } from '@/checkout/helpers/identity'

/**
 * Ordered fields to render.
 * If a paymentMethod is supplied, address fields may be added or upgraded from optional to required
 */
export function getIdentityFormFields(
  answers: IdentityFormData,
  configs: string[],
  omit: string[],
  paymentMethod?: PaymentMethodName | null,
): FormInputField[] {
  const result: FormInputField[] = []

  const fields = getAvailableFields(answers)
  const names = getFieldNames(configs)
  const requiredFieldNames = getRequiredFieldsByPaymentMethod(paymentMethod)
  const fieldNames = combineAndFilterIdentityFields(names, requiredFieldNames, omit)
  const requiredNames = new Set(requiredFieldNames)

  for (const name of fieldNames) {
    const field = fields[name]
    if (field) {
      const className = toKebabCase(field.key)
      result.push({
        ...field,
        required: requiredNames.has(name) ? true : field.required,
        // TODO Remove the dynamic class name that is not namespaced.
        className: `${className} field-${className}`,
      })
    } else {
      /* eslint-disable no-console */
      console.error(`Custom identity field '${name}' is not defined.`)
    }
  }

  return result
}

/**
 * Concatenates the inputs, removes duplicates and omitted fields, with required fields grouped together.
 */
export function combineAndFilterIdentityFields(names: string[], required: string[], omit: string[]): string[] {
  const omitted = new Set(omit)
  const fields = mergeRequiredFields(names, required)
  return fields.filter((name) => !omitted.has(name))
}

/**
 * If all of the required fields are present then do nothing.
 * If none of the required fields are present then add them all to the end of the form.
 * If at least 1 of the required fields is present then remove all fields and insert them at the index of the first field.
 */
function mergeRequiredFields(configured: string[], required: string[]): string[] {
  const remainder = new Set(required)
  const result: string[] = []
  for (let i = 0; i < configured.length; i++) {
    const name = configured[i]
    if (remainder.has(name)) {
      if (remainder.size === required.length) {
        result.push(...required)
      }
      remainder.delete(name)
    } else {
      result.push(name)
    }
  }

  if (remainder.size === 0) {
    return configured
  } else if (remainder.size === required.length) {
    return configured.concat(required)
  } else {
    return result
  }
}

function getAvailableFields(answers: IdentityFormData): Dict<FormInputField> {
  return { ...customFields(answers), ...builtInFields() }
}

function getFieldNames(configs: string[]): string[] {
  const configured = getConfiguredFieldNames(configs)
  const alwaysRequired = csvToArray('first_name, last_name, email')

  // Ensure the fields in the base form configuration are unique.
  // Array.from(new Set(...)) preserves order;
  //   - The position of first occurrence is respected.
  //   - Positions of duplicates are ignored.
  const result = new Set(prependUniqueItems(configured, alwaysRequired))

  return Array.from(result)
}

function getConfiguredFieldNames(configurations: string[]): string[] {
  const { web, config } = environment
  const base = web.identity_form || config.identity_form || []

  const extrasConfigs = configurations.map(csvToArray).sort((a, b) => a.length - b.length)

  const longest = extrasConfigs.pop()

  if (!longest) {
    return base
  } else {
    const result = prependUniqueItems(longest, base)
    return extrasConfigs.reduce(appendUniqueItems, result)
  }
}
