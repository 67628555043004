import { AU, CA, US, countryTuples } from 'country-region-data'

export const countryOptions: StringOption[] = countryTuples.map(([countryName, countryCode]) => ({
  label: countryName,
  value: countryCode.toLowerCase(),
}))

/**
 * Map of countries to names of subdivisions.
 *
 * The key is the 2-character country code in country-region-data.
 * The value is the key for the language string for the name of the country's subdivisions.
 */
export const regionLabelKeys: Dict<'province'> = {
  ca: 'province',
}

function getRegions(): Dict<StringOption[]> {
  const result = {}

  // We only support regions for these countries
  for (const country of [AU, CA, US]) {
    const [_, countryCode, countryRegions] = country
    result[countryCode.toLowerCase()] = countryRegions.map(([regionName, regionCode]) => ({
      label: regionName,
      value: regionCode,
    }))
  }

  return result
}

export const regions = getRegions()

const countryAliases = {
  'united states': 'us',
  usa: 'us',
  canada: 'ca',
}

const lowerCodes = new Set(countryOptions.map(({ value: countryCode }) => countryCode))

export function formatCountry(country?: string): string | undefined {
  if (country) {
    const lower = country.toLowerCase()
    if (lowerCodes.has(lower)) {
      return lower
    } else {
      return countryAliases[lower]
    }
  }
}
