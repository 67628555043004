
import { authenticationMethod } from '@/helpers/Environment'
import { logout } from '@/state/Authentication'
import { Cart } from '@/store/Cart'
import store from '@/store/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LanguageStrings } from '@/language/types'
import { openMembershipModal } from '@/modals/membershipModal'

@Component({ name: 'MembersMenu' })
export default class extends Vue {
  @Prop({ required: true })
  user

  t: LanguageStrings['membersMenu']

  get showAccountLinks() {
    if (authenticationMethod == null) return false
    else {
      const cart: Cart = store.getters['Cart/cart']

      // Disable login for injected carts; direct checkout payment links, cart hand-off and payment for edited orders.
      if (!cart) return true
      else return cart.ownedByPublicIdentity
    }
  }

  openMembershipModal() {
    this.close()
    openMembershipModal()
  }

  openMyAccount() {
    this.$router.push('/my-account')
    this.close()
  }

  async logOut() {
    return logout(this.$router, '/')
  }

  close() {
    const el = this.$refs.memberMenu as HTMLElement
    el.removeAttribute('open')
  }

  get membershipOnly() {
    return authenticationMethod !== 'password'
  }
}
