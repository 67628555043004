import App from '@/app/App.vue'
import { i18n } from '@/app/I18n'
import { router, Vue } from '@/app/Vue'
import EventBus from '@/helpers/EventBus'
import type { LogoURLs } from '@/helpers/ImageHelpers'
import store from '@/store/store'
import { initializeThemeVariables } from '@/themeConfig/processing'
import type { RawThemeConfig } from '@/themeConfig/types'

let lastActiveElement: HTMLElement | null = null

export function createVueApp() {
  return new Vue({
    el: '#app',
    router,
    i18n,
    store,

    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          // Sets body classes based on route name.
          // TODO Namespace dynamic class names. e.g. `route-{$name}`
          // TODO Do not use forward slashes in route names.
          if (from?.name) {
            document.body.classList.remove(...from.name.split('/'))
          }

          if (to?.name) {
            document.body.classList.add(...to.name.split('/'))
          }
        },
      },
    },

    mounted() {
      // Keep the last active element, so we can return the focus to it after a modal is closed
      window.addEventListener('focusin', (e) => {
        // Ignore elements that are in a modal. This does not support nested modals.
        if (document.querySelector('dialog')) {
          return
        }

        if (e.target) {
          lastActiveElement = e.target as HTMLElement
        }
      })
    },

    methods: {
      switchTheme(theme: { config: RawThemeConfig; logoUrls: LogoURLs }) {
        EventBus.$emit('theme changed', {
          themeOptions: initializeThemeVariables(theme.config),
          logos: theme.logoUrls,
        })
      },

      refocusLastActiveElement() {
        lastActiveElement?.focus()
      },
    },

    render: (h) => h(App),
  })
}
