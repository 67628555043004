import { i18n } from '@/app/I18n'
import { ModalCloseHandlerFn } from '@/modals/helpers/types'
import { openModal } from '@/modals/helpers/api'
import CartExpiryModal from '@/modals/CartExpiryModal.vue'

export function openCartExpiryModal(title: string, onClose?: ModalCloseHandlerFn) {
  openModal({
    name: 'cart-expiry-modal',
    title,
    closeable: false,
    size: 'sm',
    component: CartExpiryModal,
    onClose,
    describedBy: i18n.t('cartExpire.message') ? 'cart-expiry-content' : undefined,
  })
}
