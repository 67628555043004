var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radio-items"},_vm._l((_vm.radioItems),function(option){return _c('label',{key:option.id,staticClass:"radio-item form-input",class:[
      _vm.itemClassName,
      option.className,
      {
        selected: _vm.value === option.value,
        'has-description': _vm.summary(option),
        disabled: _vm.disabled,
      } ],attrs:{"for":option.id}},[_vm._l((option.badges),function(badge,index){return _c('div',{key:index,staticClass:"badge",class:badge.style},[_vm._v("\n      "+_vm._s(badge.symbol)+"\n    ")])}),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internal),expression:"internal"}],attrs:{"type":"radio","name":_vm.groupId,"required":!_vm.optional,"disabled":_vm.disabled,"id":option.id,"aria-selected":_vm.value === option.value},domProps:{"value":option.value,"checked":_vm._q(_vm.internal,option.value)},on:{"change":[function($event){_vm.internal=option.value},function($event){_vm.$emit('input', option.value)}],"invalid":function($event){$event.preventDefault();_vm.$emit('invalid')},"click":function($event){_vm.clickHandler(option.value)}}})]),_c('span',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(option.label))]),(option.labelSuffix)?_c('small',[_vm._v("\n        "+_vm._s(option.labelSuffix)+"\n      ")]):_vm._e(),_c('div',{staticClass:"prices"},[(option.originalPrice != null)?_c('Price',{staticClass:"original-price",attrs:{"value":option.originalPrice}}):_vm._e(),(option.price != null)?_c('Price',{attrs:{"value":option.price}}):_vm._e()],1)]),(_vm.summary(option))?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.summary(option))}}):_vm._e(),(_vm.description(option))?[_c('a',{staticClass:"show-benefits",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.openDescriptionModal(_vm.description(option))}}},[_vm._v("\n        "+_vm._s(_vm.showMoreLabel)+" "),_c('SvgIcon',{attrs:{"name":"ChevronRightIcon"}})],1)]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }