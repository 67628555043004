
import { API, ApiArgs } from '@/api/API'
import FormInput2 from '@/components/forms/FormInput2.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import { environment } from '@/helpers/Environment'
import { groupBy, indexItemsById, itemIDs } from '@/helpers/IndexHelpers'
import { openModal } from '@/modals/helpers/api'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TixTime } from '@/TixTime/TixTime'

// <CompletedOrdersRoute> has a request for orders too, but it has a different set of embeds.
const embeds = 'ticket,ticket_group,event_template,ticket_type,ticket.event_session'
type ApiOrderResponse = ApiResponse<
  'ticket_order' | 'ticket' | 'ticket_group' | 'ticket_type' | 'event_session' | 'event_template'
>

interface OrderItem {
  session: EventSession
  tickets: { name: string; id: string }[]
  eventName: string
  date: string
}

@Component({
  name: 'CancelFreeOrderRoute',
  components: { FormInput2 },
})
export default class extends Vue {
  @Prop({ required: true })
  id: string

  order: ApiOrderResponse | null = null
  selectedTickets: string[] = []
  closedSections = {}
  submitting: boolean = false
  successMessage: string | null = null
  errorMessage: string | null = null

  created() {
    this.getOrder().then(() => {
      if (this.order) {
        const sessions = this.order!.event_session._data
        const sectionsClosed = sessions.length > 1
        sessions.forEach((session) => Vue.set(this.closedSections, session.id, sectionsClosed))
      }
    })
  }

  getOrder() {
    return API.getUncached(`ticket_order/${this.id}`, { embed: embeds })
      .then((order) => (this.order = order as ApiOrderResponse))
      .catch((e) => {
        if (e.response.status === 404) {
          this.errorMessage = 'We could not find that order.'
        } else {
          this.errorMessage = 'Oops! Sorry, something went wrong. Please try again.'
          // Surface unexpected errors to TrackJS.
          throw e
        }
      })
  }

  cancelTickets(ticketIds, successMessage) {
    openModal({
      name: 'confirm-cancel-modal',
      component: ConfirmModal,
      title: this.t.cancelModalTitle as string,
      props: {
        message: this.$tc('cancelFreeOrderRoute.cancelModalSubtext', ticketIds.length),
      },
      describedBy: 'confirm-modal-message',
      onClose: (data) => {
        if (!data?.confirmed) return
        this.submitting = true
        this.successMessage = null
        this.errorMessage = null

        const payload: ApiArgs = {
          body: {
            gateway_data: {},
            tickets: ticketIds.map((id) => ({ ticket_id: id })),
          },
        }

        API.post(`ticket_order/${this.id}/cancel`, payload)
          .then(() => API.post(`ticket_order/${this.id}/email`, { body: { log_context: 'order_cancel' } }))
          .then(() => {
            this.successMessage = successMessage
            this.selectedTickets = []
            return this.getOrder()
          })
          .catch((e) => {
            this.errorMessage = 'Oops! Sorry, something went wrong. Please try again.'
            // Surface unexpected errors to TrackJS.
            throw e
          })
          .finally(() => {
            this.submitting = false
          })
      },
    })
  }

  cancelOrder() {
    const ticketIds = itemIDs(this.validTickets)
    const successMessage = this.t.orderCancelled
    this.cancelTickets(ticketIds, successMessage)
  }

  cancelSelectedTickets() {
    const ticketIds = this.selectedTickets
    const successMessage = this.$tc('cancelFreeOrderRoute.ticketsCancelled', ticketIds.length)
    this.cancelTickets(ticketIds, successMessage)
  }

  toggleSelectedTickets(e, tickets) {
    const ids = itemIDs(tickets)
    if (e.target.checked) {
      this.selectedTickets = this.selectedTickets.concat(ids)
    } else {
      this.selectedTickets = this.selectedTickets.filter((id) => !ids.includes(id))
    }
  }

  allTicketsSelected(tickets) {
    return tickets.every((ticket) => this.selectedTickets.includes(ticket.id))
  }

  toggleSectionVisibility(id) {
    Vue.set(this.closedSections, id, !this.closedSections[id])
  }

  getFormattedDate(datetime) {
    return new TixTime(datetime).format('LONGER_DATE')
  }

  getFormattedTime(datetime) {
    return new TixTime(datetime).format('LT')
  }

  get orderData() {
    if (this.totalValidTickets === 0) return null

    const templatesById = indexItemsById(this.order!.event_template._data)
    const ticketTypesById = indexItemsById(this.order!.ticket_type._data)
    const ticketsBySession = groupBy('event_session_id', this.validTickets)
    const sessionAndDays: OrderItem[] = []

    this.order!.event_session!._data.forEach((session) => {
      const tickets = ticketsBySession[session.id]
      if (!tickets) return
      const ticketsByTypeId = groupBy('ticket_type_id', tickets)
      const normalizedTickets: Array<{ name: any; id: string }> = []
      Object.values(ticketsByTypeId).map((typeTickets) => {
        typeTickets.forEach((ticket, index) => {
          const params = {
            typeName: ticketTypesById[ticket.ticket_type_id].name,
            index: index + 1,
          }
          normalizedTickets.push({
            name: ticket.admit_name || this.$t('cancelFreeOrderRoute.ticketName', '', params),
            id: ticket.id,
          })
        })
      })

      sessionAndDays.push({
        session,
        tickets: normalizedTickets,
        eventName: templatesById[session.event_template_id].name,
        date: new TixTime(session.start_datetime).format('DATE'),
      })
    })

    const sessionsByDay = groupBy('date', sessionAndDays)
    return Object.keys(sessionsByDay).map((date) => ({ date, sessions: sessionsByDay[date] }))
  }

  get orderCode() {
    return this.order!.ticket_order!._data[0].order_number
  }

  get activeTickets() {
    return this.order!.ticket!._data.filter((ticket) => ticket.state !== 'cancelled')
  }

  get validTickets() {
    const eventSessionsById = indexItemsById(this.order!.event_session!._data)
    return this.activeTickets.filter(({ adjusted_value, event_session_id }) => {
      const session = eventSessionsById[event_session_id!]

      return session && adjusted_value === '0' && new TixTime(session.start_datetime).isAfter(new TixTime())
    })
  }

  get totalValidTickets() {
    return this.validTickets.length
  }

  get submitDisabled() {
    return this.selectedTickets.length === 0 || this.submitting
  }

  // TODO: Duplicated from Header.vue
  get logoExternalLink(): any {
    return environment.web.external_urls?.logo
  }

  get manageOrderMessage() {
    return this.$portalString.manage_order_message ?? this.t.cancelTicketsSubtext
  }
}
