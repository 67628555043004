import { openModal } from '@/modals/helpers/api'
import DescriptionModal from '@/modals/DescriptionModal.vue'

export function openDescriptionModal(content: string, title: string | undefined = '') {
  openModal({
    name: 'description-modal',
    component: DescriptionModal,
    title,
    mobile: 'full-screen',
    props: { content },
    describedBy: 'description-modal-content',
  })
}
