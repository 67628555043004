
import { Component, Vue } from 'vue-property-decorator'
import { isLoggedIn, logout } from '@/state/Authentication'
import { isCurrentMember } from '@/state/Membership'
import LoginForm from '@/components/forms/LoginForm.vue'

@Component({
  components: { LoginForm },
})
export default class MyAccount extends Vue {
  async logOut() {
    return logout(this.$router, '/')
  }

  get activeRoute() {
    return this.$route.path
  }

  get hasMembership(): boolean {
    return isCurrentMember()
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }
}
