import { API } from '@/api/API'
import { CartApiResponse, fetchCart } from '@/api/Cart'
import type { EventDetails, EventWithConfig } from '@/api/types/processedEntities'
import { isAnchor, isDependency } from '@/helpers/Anchors'
import { groupAsMap, itemIDs } from '@/helpers/IndexHelpers'
import { sum } from '@/helpers/Math'
import { isMembershipEvent } from '@/helpers/MiscellaneousHelpers'
import type { CartChanges, ModifyCartPayload } from '@/helpers/Reserve'
import type { CartItem } from '@/store/CartItem'
import { TixTimeDuration } from '@/TixTime/Duration'
import { TixTime } from '@/TixTime/TixTime'
import VueRouter from 'vue-router'
import { cartItemImageUrl, imageData } from './ImageHelpers'

export type PayButtonContext = 'checkout' | 'default'

// Do not import the router here. That causes a cyclic dependency that surfaces as a failed Cypress test when rendering
// <LoginForm> on <LoginOrGuestDialog>.

function sumTicketPrices(tickets: Ticket[]): number {
  const values = tickets.map((ticket) => Number(ticket.face_value))
  return sum(values)
}

export function findStripeFee(items: CartFee[]) {
  // API always includes the Stripe cart fees if stripe is enabled on the tenant.
  return items.find((item) => item.gateway_id === 'stripe') as CartFee
}

export function findStripeOrFreeFee(items: CartFee[]) {
  // Necessary to also include free gateways for tenants who don't have stripe enabled.
  return items.find((item) => item.gateway_id === 'stripe' || item.gateway_id === 'free') as CartFee
}

export function convertCartChangesToModifyApiPayload(changes: CartChanges): ModifyCartPayload {
  const payload: ModifyCartPayload = {}

  // Properties are specified in the same order that the API endpoint executes them, for convenience.
  if (changes.create || changes.campaign) {
    payload.create = { channel_id: 'web', campaign: changes.campaign }
  }
  if (changes.remove && changes.remove.length > 0) {
    payload.remove = { tickets: Array.from(changes.remove) }
  }
  if (changes.preloadedPromoCodes && changes.preloadedPromoCodes?.length > 0) {
    payload.apply_code_default = {
      codes: changes.preloadedPromoCodes,
      ignore_errors: true,
      require_benefit: false,
    }
  }

  if (changes.preReservePromoCodes && changes.preReservePromoCodes.codes.length > 0) {
    payload.apply_code_before = changes.preReservePromoCodes
  }
  if (changes.add && changes.add.length > 0) {
    payload.reserve = { tickets: changes.add, additional_info: changes.additionalInfo }
  }
  if (changes.postReservePromoCodes && changes.postReservePromoCodes.codes.length > 0) {
    payload.apply_code = changes.postReservePromoCodes
  }
  return payload
}

export function ticketsToCartItems(
  groupedTickets: Map<EventSession | EventWithConfig, Ticket[]>,
  {
    ticketTypes,
    ticketGroups,
    templates,
    venues,
    allDaySessionIds,
  }: {
    templates: Dict<EventWithConfig>
    ticketTypes: Dict<TicketType>
    ticketGroups: Dict<TicketGroup>
    venues: Dict<Venue>
    allDaySessionIds: Set<string>
  },
): CartItem[] {
  return Array.from(groupedTickets, ([key, tickets]) => {
    const session = 'event_template_id' in key ? (key as EventSession) : null
    const template = 'event_template_id' in key ? templates[key.event_template_id] : (key as EventDetails)
    const venue = venues[template.venue_id]
    const byType = Array.from(groupAsMap(tickets, 'ticket_type_id', ticketTypes))

    const base = {
      event: template,
      templateId: template.id,
      name: template.name,
      meta: template.meta,
      image: imageData(template, cartItemImageUrl),
      allDay: session ? allDaySessionIds.has(session.id) : false,
      faceValue: sumTicketPrices(tickets),
      isAnchor: isAnchor(template),
      isMembershipEvent: isMembershipEvent(template, tickets),
      isDependency: isDependency(template, tickets),
      ticketIds: itemIDs(tickets),
      types: byType
        .map(([type, typeTickets]) => ({
          type,
          name: type.name,
          ticketPrice: Number(type.currency_amount),
          ticketCount: typeTickets.length,
          totalPrice: sumTicketPrices(typeTickets),
          group: ticketGroups[type.ticket_group_id],
        }))
        .sort((a, b) => a.type._rank - b.type._rank)
        .sort((a, b) => a.group._rank - b.group._rank),
    }

    if (session) {
      return {
        ...base,
        sessionId: session.id,
        startTime: new TixTime(session.start_datetime, venue.timezone),
        endTime: new TixTime(session.end_datetime, venue.timezone),
        allDay: allDaySessionIds.has(session.id),
      }
    } else {
      const [ticket] = tickets as AdmissionPassTicket[]
      return {
        ...base,
        validTo: new TixTime(ticket.valid_to, venue.timezone),
        validFrom: new TixTime(ticket.valid_from, venue.timezone),
        allDay: false,
      }
    }
  })
}

export function fetchOrderAndCart(
  orderId: string,
  allTickets?: boolean,
): Promise<{
  cart: CartApiResponse
  order: TicketOrder
}> {
  // TODO: Test invalid order IDs or valid order IDs with invalid carts; E.g. pending carts.
  return API.getCached<'ticket_order'>(`ticket_order/${orderId}`).then((orderResponse) => {
    const order = orderResponse.ticket_order._data[0]
    return fetchCartAndHandleExpired(order.cart_id, allTickets).then((cart) => {
      return { cart, order }
    })
  })
}

export function fetchCartAndHandleExpired(cartId: string, allTickets?: boolean): Promise<CartApiResponse> {
  const query = { _all_tickets: allTickets }
  return fetchCart(cartId, query).then((cart) => {
    const expiresIn = cart.cart._data[0].expires_in
    if (expiresIn !== null && expiresIn < 30) {
      return API.delete(`cart/${cartId}`).then(() => {
        return fetchCart(cartId, query)
      })
    }

    return cart
  })
}

export function timeTillExpiry(expiry: number): TixTimeDuration {
  return new TixTime().durationTo(new TixTime(expiry))
}

export function onSubmit(context: PayButtonContext, router: VueRouter, emit: () => void) {
  if (context === 'checkout') {
    emit()
  } else {
    router.push('/checkout')
  }
}

export interface ProcessedScheduledPayment extends ScheduledPayment {
  dueDate: string
  status: 'Overdue' | 'Upcoming'
}

export function processScheduledPayment(
  scheduledPayment: ScheduledPayment,
  timezone: string,
): ProcessedScheduledPayment {
  // Payments are scheduled 12AM next day, so bring back to the previous day to display correctly
  const dueDate = new TixTime(scheduledPayment.due, timezone).subtract(1, 'second')
  const status = dueDate.isAfter(new TixTime(null, timezone)) ? 'Upcoming' : 'Overdue'
  return {
    ...scheduledPayment,
    dueDate: dueDate.format('LONGER_DATE'),
    status,
  }
}

type CompletedPaymentStatus = 'Paid' | 'Refund' | 'Dispute Opened' | 'Dispute Closed' | 'Dispute Fee'

export interface ProcessedCompletedPayments extends GatewayAudit {
  occurredDate: string
  status: CompletedPaymentStatus
}

export function processCompletedPayment(completedPayment: GatewayAudit, timezone: string): ProcessedCompletedPayments {
  const statusMap: Record<GatewayAuditAction, CompletedPaymentStatus> = {
    capture: 'Paid',
    refund: 'Refund',
    dispute_opened: 'Dispute Opened',
    dispute_closed: 'Dispute Closed',
    dispute_fee: 'Dispute Fee',
  }
  const occurredOn = new TixTime(completedPayment.occurred_on, timezone)

  return {
    ...completedPayment,
    occurredDate: occurredOn.format('LONGER_DATE'),
    status: statusMap[completedPayment.audit_action],
  }
}

export function getUpcomingAmount(scheduledPayments: ProcessedScheduledPayment[]) {
  return Number(scheduledPayments.find((p) => p.status === 'Upcoming')?.amount ?? 0)
}

export function getOverdueAmount(scheduledPayments: ProcessedScheduledPayment[]) {
  const overduePayments = scheduledPayments.filter((p) => p.status === 'Overdue')
  return sum(overduePayments.map((p) => Number(p.amount)))
}
